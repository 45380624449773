<template>
  <div>
    <v-sheet class="d-flex justify-center">
      <v-sheet :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'" class="pt-8" width="782">
        <div class="nunito">
          <h2 :style="`color: ${wsDARKER}`" class="font-weight-medium">Політика конфіденційності</h2>
          <h5 class="font-weight-light" :style="`color: ${wsACCENT}`">Редакція від 21 травня 2022 року</h5>
          <p class="mt-6">Комп‘ютерна програма «WeStudyTM Online» (далі може визначатися, як – «Платформа»,
            «Система», «Ліцензійне програмне забезпечення», «Твір», «Комп‘ютерна програма», «Об‘єкт права
            інтелектуальної власності», «Об‘єкт інтелектуальної власності» чи «Об‘єкт авторського права») –
            спеціалізоване програмне забезпечення, набір інструкцій у вигляді слів, цифр, кодів, схем, символів чи у
            будь-якому іншому вигляді, виражених у формі, придатній для зчитування комп‘ютером, які приводять його у
            дію для досягнення певної мети або результату (прикладна програма, виражена у вихідному або об’єктному
            кодах)</p>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">I. Загальні положення та предмет
            Політики Конфіденційності</h3>
          <ol style="list-style-type: decimal;">
            <li>Ця Політика Конфіденційності є невід’ємною частиною Ліцензійного публічного договору між Вами,
              Користувачем нашої електронної платформи для взаємодії між рекрутаційними агентами, викладачами,
              студентами і адміністраціями навчальних закладів в зв’язку з освітньо-виховним процесом в навчальних
              закладах (далі — <strong>«Платформа»</strong>) та нами, Товариством з Обмеженою Відповідальністю
              «ВІСТАДІ», (надалі —
              «ВІСТАДІ» або <strong>«Ми»</strong>).
            </li>
            <li>Ця Політика Конфіденційності пояснює, яким чином обробляються Ваші персональні дані, з якою метою,
              де ці дані зберігаються, а також Ваші права щодо даних, які Ви нам надаєте під час Вашого користування
              Платформою, яка знаходиться за адресою <a href="https://westudy.ua/online" target="_blank">[https://westudy.ua/online]</a>.
              Будь ласка, уважно ознайомтеся з усіма пунктами цієї Політики Конфіденційності.
            </li>
            <li>З метою покращення розуміння Політики Конфіденційності, ТОВ «ВІСТАДІ» додав короткі пояснення до
              кожного розділу Політики Конфіденційності. Ці пояснення не є частиною Політики Конфіденційності та
              мають рекомендаційний характер. Ви маєте обов’язок прочитати цю Політику Конфіденційності в
              оригінальній формі.
            </li>
            <li>Будь-які терміни, які згадуються, але не визначені в цьому документі, мають відповідне визначення в
              Угоді Користувача.
            </li>
            <ul style="list-style-type: square;">
              <li>Які категорії персональних даних ми обробляємо?</li>
              <li>Які цілі обробки Ваших персональних даних?</li>
              <li>Як ми отримуємо Ваші дані?</li>
              <li>На яких підставах ми обробляємо Ваші персональні дані?</li>
              <li>Особи, які можуть мати доступ до Ваших персональних даних</li>
              <li>Як довго ми зберігаємо Ваші персональні дані?</li>
              <li>Як захищаються Ваші персональні дані?</li>
              <li>Обробка персональних даних дітей</li>
              <li>Які права Ви маєте щодо Ваших персональних даних?</li>
              <li>Видалення та редагування персональних даних</li>
              <li>Посилання на інші сайти</li>
              <li>Зміни в Політиці Конфіденційності</li>
              <li>Зворотній зв’язок</li>
            </ul>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">II. Які категорії персональних даних ми
            обробляємо?</h3>
          <ol style="list-style-type: decimal;">
            <li>Ми збираємо деякі персональні дані про Ваші пристрої автоматично, коли Ви користуєтесь нашим Сайтом
              або Платформою. У такі дані входять дата та час входу на Платформу, відвідані сторінки, IP-адреса,
              тип, марка, модель і операційна система пристрою, з якого Ви зайшли.
            </li>
            <li>Ми можемо збирати деяку персональну інформацію про Вас, коли Ви заповнюєте контактні форми,
              розміщені на нашому Сайті, або коли ви реєструєте обліковий запис для використання можливостей
              Платформи. Під час реєстрації ми просимо Вас надати нам Ваші ім’я, прізвище, електронну пошту та
              пароль.
            </li>
            <li>Також залежно від типу Користувача, прив’язаного до Вашого облікового запису, ми можемо збирати та
              обробляти різні категорії персональних даних.
            </li>
            <li>У випадку, якщо Ви маєте тип Користувача «Вчитель», ми можемо обробляти такі категорії персональних
              даних про Вас:
            </li>
            <ul style="list-style-type: square;">
              <li>особисту інформацію (ім’я, прізвище, по батькові, дату народження, стать);</li>
              <li>контактні дані (наприклад, номер мобільного телефону, електронну пошту);</li>
              <li>зображення з Вами;</li>
              <li>інформацію, пов’язану з викладанням (робочі години, предмет викладання, а також класи, які вчитель
                навчає);
              </li>
              <li>будь-яку іншу персональну інформацію, яку Ви можете завантажити або поділитись на Платформі,
                наприклад, залишивши коментар до посту.
              </li>
            </ul>
            <li>У випадку, якщо Ви маєте тип Користувача «Рекрутаційний агент», ми можемо обробляти такі категорії
              персональних даних про Вас:
            </li>
            <ul style="list-style-type: square;">
              <li>особисту інформацію (ім’я, прізвище, побатькові, дату народження, стать);</li>
              <li>контактні дані (наприклад, номер мобільного телефону, електронну пошту);</li>
              <li>зображення з Вами;</li>
              <li>інформацію щодо вашої дитини або дітей, які є Користувачами Платформи, а також інформацію щодо
                Вашого партнера;
              </li>
              <li>будь-яку іншу персональну інформацію, яку Ви можете завантажити або поділитись на Платформі,
                наприклад, залишивши коментар до посту.
              </li>
            </ul>
            <li>У випадку, якщо Ви маєте тип Користувача «Адміністратор», ми можемо обробляти такі категорії
              персональних даних про Вас:
            </li>
            <ul style="list-style-type: square;">
              <li>особисту інформацію (ім’я, прізвище, побатькові, дату народження, стать);</li>
              <li>контактні дані (наприклад, номер мобільного телефону, електронну пошту);</li>
              <li>зображення з Вами;</li>
              <li>інформацію пов’язану з посадою в навчальному закладі (робочі години, посада);</li>
              <li>будь-яку іншу персональну інформацію, яку Ви можете завантажити або поділитись на Платформі,
                наприклад, залишивши коментар до посту.
              </li>
            </ul>
            <li>У випадку, якщо ви маєте тип Користувача «Студент», ми можемо обробляти такі категорії персональних
              даних про Вас:
            </li>
            <ul style="list-style-type: square;">
              <li>особисту інформацію (ім’я, прізвище, побатькові, дату народження, стать);</li>
              <li>контактні дані (номер мобільного телефону, електронну пошту);</li>
              <li>зображення з Вами;</li>
              <li>інформацію, що стосується навчання;</li>
              <li>будь-яку іншу персональну інформацію, яку Ви можете завантажити або поділитись на Платформі,
                наприклад, залишивши коментар до посту.
              </li>
            </ul>
            <li>Слід зазначити, що ми також отримуємо деяку персональну інформацію про Вас при зборі кукі
              («реп’яшків»).
            </li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">III. Які цілі обробки Ваших персональних
            даних?</h3>
          <ol style="list-style-type: decimal;">
            <li>Ми обробляємо Ваші персональні дані для таких цілей:</li>
            <ul style="list-style-type: square;">
              <li>виконання наших зобов’язань в зв’язку з будь-якими договорами, що існують між нами, включаючи
                угоду про користування;
              </li>
              <li>забезпечення доступу і нормального функціонування Платформи;</li>
              <li>персоналізації Вашого акаунту на Платформі;</li>
              <li>забезпечення спілкування та взаємодії між Користувачами Платформи щодо навчального процесу;</li>
              <li>надання Вам підтримки щодо наших послуг і Платформи;</li>
              <li>покращення роботи нашої Платформи, веб-сайту та додатків шляхом виправлення недоліків, аналізу
                даних, тестування;
              </li>
              <li>підтримка безпеки та цілісності Платформи;</li>
              <li>забезпечити дотримання застосовного права, наших умов користування та Політики конфіденційності;
              </li>
              <li>інформування Вас про зміни в Платформи.</li>
            </ul>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">IV. Як ми отримуємо Ваші дані?</h3>
          <ol style="list-style-type: decimal;">
            <li>Ми отримуємо деякі Ваші персональні дані від Вас (наприклад, під час реєстрації акаунту чи протягом
              користування Платформою), а також третіх осіб. Наприклад, перед тим, як надати вам код для доступу до
              Платформи, співробітники Вашого освітнього закладу мають передати певні персональні дані до нашої
              Платформи. Також ми можемо отримувати інформацію про Вашу успішність завдяки вчителям, які мають право
              виставляти вам оцінки та відвідуваність.
            </li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">V. На яких підставах ми обробляємо Ваші
            персональні дані?</h3>
          <ol style="list-style-type: decimal;">
            <li>Згідно зі статтею 11 Закону України «Про захист персональних даних» ми обробляємо Ваші персональні
              дані на таких підставах, включаючи:
            </li>
            <ul style="list-style-type: square;">
              <li>зобов’язання, які виникають в результаті укладання договору (Публічної оферти) з нами;</li>
              <li>згода суб’єкта персональних даних;</li>
              <li>необхідність захисту законних інтересів володільця персональних даних або третьої особи, якій
                передаються персональні дані, крім випадків, коли потреби захисту основоположних прав і свобод
                суб’єкта персональних даних у зв’язку з обробкою його даних переважають такі інтереси;
              </li>
              <li>покращити сервіс;</li>
              <li>забезпечити дотримання застосовного права, Публічної оферти, Умов та правил використання та
                Політики Конфіденційності;
              </li>
              <li>забезпечити безпеку та цілісність нашої Платформи.</li>
            </ul>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">VI. Особи, які можуть мати доступ до
            Ваших персональних даних.</h3>
          <ol style="list-style-type: decimal;">
            <li>Ваші персональні дані, отримані Платформою за Вашою згодою, можуть передаватися третім особам для
              надання та покращення окремих сервісів Платформи за умови дотримання конфіденційності Ваших
              персональних даних, а саме постачальникам, які надають необхідні для функціонування Платформи послуги.
              Ми зобов’язали таких постачальників зберігати конфіденційність Ваших персональних даних. Послуги від
              таких постачальників можуть включати:
            </li>
            <ul style="list-style-type: square;">
              <li>хостинг нашої Платформи;</li>
              <li>веб-аналітику;</li>
              <li>електронну пошту;</li>
              <li>email-розсилку.</li>
            </ul>
            <li>Ми також можемо передавати Вашу персональну інформацію за умови дотримання конфіденційності Ваших
              персональних даних в таких випадках:
            </li>
            <ul style="list-style-type: square;">
              <li>щоб забезпечити цілісність нашої Платформи, для застосування запобіжних заходів проти
                притягнення до відповідальності чи для сприяння судовим процесам або запитам інших державних
                органів;
              </li>
              <li>коли, на наш погляд, необхідно надати таку інформацію задля попередження завдання фізичної шкоди
                чи фінансових збитків, чи у зв’язку з розслідуванням стосовно підозрілої чи фактичної незаконної
                діяльності;
              </li>
              <li>у разі продажу нашого бізнесу або частини активів третій стороні ми можемо передати Ваші
                персональні дані такій стороні бізнесу або активів;
              </li>
              <li>у разі передачі афілійованим компаніям з метою, передбаченою в цій Політиці конфіденційності.</li>
            </ul>
            <li>Для повноцінної реалізації окремих функцій Платформи, частина Ваших персональних даних, наданих з
              Вашого дозволу Платформі, може бути доступна для перегляду іншими Користувачами.
            </li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">VII. Як довго ми зберігаємо Ваші
            персональні дані?</h3>
          <ol style="list-style-type: decimal;">
            <li>Ваші персональні дані зберігаються на нашій Платформі без обмежень у часі</li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">VIII. Як захищаються Ваші персональні
            дані?</h3>
          <ol style="list-style-type: decimal;">
            <li>Ми використовуємо загальноприйняті стандарти технологічного та операційного захисту інформації та
              персональних даних від втрати, неправильного використання, зміни або знищення. Ми забезпечуємо
              застосування всіх відповідних зобов’язань щодо дотримання конфіденційності, а також технічних і
              організаційних заходів безпеки для запобігання несанкціонованому чи незаконному розголошенню або
              обробці такої інформації та даних, їх випадковій втраті, знищенню або пошкодженню. Постачальника
              хмарних послуг, на серверах якого зберігається WeStudy знаходиться на території України. Необхідною
              вимогою WeStudy до Постачальника був Атестат відповідності вищого гатунку в Україні щодо Комплексної
              системи захисту інформації, яка необхідна для того, щоб ваші персональні дані не потрапили до рук
              третіх осіб і зловмисників, інформацію неможливо було змінювати або використовувати поза правилами
              політики безпеки, а також її неможливо втратити через несправність обладнання.
            </li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">IX. Обробка персональних даних
            дітей.</h3>
          <ol style="list-style-type: decimal;">
            <li>Ми розуміємо надзвичайну важливість забезпечення конфіденційності і захисту персональних даних
              дітей, які відвідують та користуються нашою Платформою, і вважаємо за необхідне заохочувати інтерес
              батьків та опікунів до онлайн діяльності своїх дітей і контролю їхніх дій у мережі Інтернет.
            </li>
            <li>Ми навмисне не обробляємо без згоди батьків персональні дані дітей, які не досягли повноліття. Якщо
              нам стане відомо, що дані неповнолітньої чи малолітньої особи були передані нам без згоди її законних
              представників, ми негайно видалимо персональні дані цієї особи.
            </li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">X. Які права Ви маєте щодо Ваших
            персональних даних</h3>
          <ol style="list-style-type: decimal;">
            <li>Згідно зі статтею 8 Закону України «Про захист персональних даних», Користувачі мають право (окрім
              випадків, коли Користувач — неповнолітня або малолітня дитина, в такому разі, ці права мають тільки
              Один з Батьків або Законний Представник):
            </li>
            <ul style="list-style-type: square;">
              <li>знати про місцезнаходження персональних даних, яка містить його персональні дані, її призначення
                та найменування, місцезнаходження та/або місце проживання (перебування) володільця чи розпорядника
                персональних даних або дати відповідне доручення щодо отримання цієї інформації уповноваженим ним
                особам, крім випадків, встановлених законом;
              </li>
              <li>отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про
                третіх осіб, яким передаються його персональні дані;
              </li>
              <li>на доступ до своїх персональних даних;</li>
              <li>отримувати не пізніше 30 (тридцяти) календарних днів з дня надходження запиту, крім випадків,
                передбачених законом, відповідь про те, чи зберігаються його персональні дані у відповідній базі
                персональних даних, а також отримувати зміст його персональних даних, які зберігаються;
              </li>
              <li>пред’являти вмотивовану вимогу володільцю персональних даних із запереченням проти обробки своїх
                персональних даних;
              </li>
              <li>пред’являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних, якщо ці дані
                обробляються незаконно чи є недостовірними;
              </li>
              <li>на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення,
                пошкодження у зв’язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на
                захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію
                фізичної особи;
              </li>
              <li>з• звертатися із скаргами на обробку своїх персональних даних до органів державної влади та
                посадових осіб, до повноважень яких належить забезпечення захисту персональних даних, або до суду;
              </li>
              <li>застосовувати засоби правового захисту в разі порушення законодавства про захист персональних
                даних;
              </li>
              <li>вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання
                згоди;
              </li>
              <li>відкликати згоду на обробку персональних даних;</li>
              <li>знати механізм автоматичної обробки персональних даних;</li>
              <li>на захист від автоматизованого рішення, яке має для нього правові наслідки.</li>
            </ul>
            <li>Ви зобов’язані вносити до Вашого облікового запису правдиву, точну та повну інформацію, а також
              забезпечувати її актуальність.
            </li>
            <li>Якщо Ви бажаєте використати будь-які з вищенаведених прав, будь ласка, зверніться до нас, написавши
              електронного листа на email: <a href="mailto:info@westudy.ua">info@westudy.ua</a>. Протягом 10 днів з
              моменту надходження запиту ми повідомимо Вас про те, чи буде цей запит задоволений. Якщо наше рішення
              щодо задоволення Вашого запиту буде позитивним, ми докладемо всіх зусиль, щоб задовольнити його
              протягом 30 днів з моменту його надходження.
            </li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XI. Видалення та редагування
            персональних даних</h3>
          <ol style="list-style-type: decimal;">
            <li>Якщо Ви хочете редагувати чи видалити свої персональні дані Вашого Акаунту, Ви можете зробити це
              самостійно в налаштуваннях Акаунту. Видалення чи зміна в персональних даних Акаунту не означає
              видалення чи зміну в персональних даних Профілю, що підключений до Вашого Акаунту.
            </li>
            <li>Для того, щоб редагувати чи видалити персональні дані, що стосуються Вашого Профілю, Ви маєте
              звернутися до Адміністратора з клопотанням про видалення, що є відповідальним за ведення та
              адміністрування персональних даних Користувачів на Платформі, як зазначено в Згоді на Передачу та
              Обробку Даних між Вами та Адміністратором. Адміністратор має видалити ваш Профіль та всі Ваші
              Персональні Дані протягом 7 (семи) днів з моменту Вашого звернення. Якщо Адміністратор не видалив Ваш
              Профіль вчасно, будь ласка, зверніться до нас для того, щоб ми задовольнити Ваш запит.
            </li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XII. Посилання на інші сайти</h3>
          <ol style="list-style-type: decimal;">
            <li>Наша платформа може містити посилання на веб-сайти, які стали доступними завдяки діям інших
              Користувачів Платформи і які не мають відношення до нас і нашої Платформи. Ми не несемо будь-яку
              відповідальність за ці веб-ресурси.
            </li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XIII. Зміни в Політиці
            Конфіденційності</h3>
          <ol style="list-style-type: decimal;">
            <li>Умови Політики Конфіденційності можуть змінюватись в односторонньому порядку без згоди Користувача.
              У разі матеріальних змін в умовах Політики Конфіденційності, ми повідомимо Вам про це електронною
              поштою за 10 днів до набуття чинності цих змін.
            </li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XIV. Зворотній зв’язок</h3>
          <ol style="list-style-type: decimal;">
            <li>Якщо у вас є питання щодо цього документа або обробки Ваших персональних даних, Ви можете звернутися
              до нас, написавши нам електронного листа на <a href="mailto:info@westudy.ua">info@westudy.ua</a></li>
          </ol>

        </div>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "HomeAbout"
}
</script>

<style scoped>

</style>